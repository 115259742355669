<template>
  <div class="row" v-show="mostrar_instalador">
    <div class="col-md-6 col-12">
      <div class="d-block d-sm-none">
        <div class="btn-group install-app-btn w-100" id="installAppMov">
          <button class="btn btn-primary pt-4 pb-4 btn-block text-truncate" @click="instaladorArgus()">
            <i class="mdi mdi-progress-download"></i>
            Instalar ArgusBlack
          </button>
          <button class="btn btn-primary pt-4 pb-4 btn-block-2" @click="quitarBoton()">
            <i class="mdi mdi-close text-dark"></i>
          </button>
        </div>
      </div>
      <div class="d-none d-sm-block">
        <div class="btn-group install-app-btn w-100" id="installAppEsc">
          <button class="btn btn-primary text-truncate" @click="instaladorArgus()">
            <i class="mdi mdi-progress-download"></i>
            Instalar ArgusBlack
          </button>
          <button class="btn btn-primary" @click="quitarBoton()">
            <i class="mdi mdi-close text-dark"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BotonInstalacion',
  computed: {
    mostrar_instalador: function(){
      let mostrarInstalador = true
      if(localStorage.getItem("argusblack.mostrar_instalador") !== null && 
        localStorage.getItem("argusblack.mostrar_instalador") !== true &&
        localStorage.getItem("argusblack.mostrar_instalador") !== 'true') mostrarInstalador = false
      else mostrarInstalador = true
      return mostrarInstalador
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      let btnEsc = document.getElementById('installAppEsc')
      let btnMov = document.getElementById('installAppMov')
      btnEsc.style.display = 'block'
      btnMov.style.display = 'block'
      
      window.deferredPrompt = e
    })
  },
  mounted: function() {
    if(window.deferredPrompt != null) {
      let btnEsc = document.getElementById('installAppEsc')
      let btnMov = document.getElementById('installAppMov')
      btnEsc.style.display = 'block'
      btnMov.style.display = 'block'
    }
  },
  methods: {
    quitarBoton: function() {
      let btnEsc = document.getElementById('installAppEsc')
      let btnMov = document.getElementById('installAppMov')
      btnEsc.style.display = 'none'
      btnMov.style.display = 'none'

      localStorage.setItem('argusblack.mostrar_instalador', false)
    },

    instaladorArgus:function() {
      if (window.deferredPrompt !== null) {
        window.deferredPrompt.prompt()
        const { outcome } = window.deferredPrompt.userChoice
        if (outcome === 'accepted') {
          window.deferredPrompt = null
        }
      } else {
        iu.msg.warning("Debe de recargar la vista para cargar el instalador correctamente")
      }
    }
  }
}
</script>

<style scoped>
.install-app-btn {
  display: none;
}
.no-border {
  border-radius: 0 !important;
}
.text-dark {
  color: "#1D3A70"
}
.btn-block {
  display: inline-block;
  width: 80%;
}
.btn-block-2 {
  display: inline-block;
  width: 20%;
}
</style>