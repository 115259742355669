<template v-slot:cuerpo>
  <Layout :tituloPagina="'Modulos'">
    <div class="row">
      <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6" v-for="modulo in modulos" :key="modulo.nombre">
        <div class="card cursor" @click="irAModulo(modulo)" 
          :class="{ 'bg-success bg-gradient': moduloSeleccionado() == modulo.nombre }">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0 me-3">
                <div class="avatar">
                  <div class="avatar-title rounded bg-gradient" 
                    :class="{
                      'bg-primary': moduloSeleccionado() != modulo.nombre, 
                      'bg-info': moduloSeleccionado() == modulo.nombre
                    }"
                  >
                    <eva-icon :name="modulo.icono" class="fill-white font-size-20"></eva-icon>
                  </div>
                </div>
              </div>
              <div class="flex-grow-1 text-truncate w-100">
                <h5 class="mb-1 text-truncate" :class="{ 'text-white': moduloSeleccionado() == modulo.nombre }">{{modulo.nombre.toUpperCase()}}</h5>
                <p class="mb-1 text-truncate" 
                  :class="{
                    'text-muted': moduloSeleccionado() != modulo.nombre, 
                    'text-white': moduloSeleccionado() == modulo.nombre
                  }"
                >
                  {{modulo.descripcion}}
                </p>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
    </div>
    <boton-instalacion/>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import CuentaSrv from "@/services/CuentaSrv.js"
import BotonInstalacion from "@/components/BotonInstalacion.vue";

import { todoComputed, todoMethods, todoGetters } from "@/state/helpers";

export default {
  name: 'Modulos',
  components: {
    Layout,
    BotonInstalacion
  },
  data() {
    return {
      estado_modulo: false
    };
  },
  computed: {
    ...todoComputed,
    ...todoGetters
  },

  watch: {
    modulo(nuevoModulo, antiguoModulo) {
      if ( nuevoModulo == antiguoModulo ) return;
      // iu.refrescarInspinia()
    },
  },
  methods: {
    ...todoMethods,
    irAModulo(modulo) {
      var self = this

      if(!self.$store.state.todo.susargus) {
        iu.msg.warning(`No puedes usar el módulo \"${modulo.nombre.toUpperCase()}\" debido a la suspensión de tu cuenta.`)
        this.$router.push({name: 'sistemaCuenta'})
        return
      }

      CuentaSrv.estadoModulo(modulo.nombre).then(response => {
        self.estado_modulo = response.data

        if(self.estado_modulo){
          this.actualizarModuloSeleccionado({ modulo: modulo.nombre })
          sessionStorage.setItem('argusblack.modulo', modulo.nombre)
          localStorage.setItem('argusblack.modulo', modulo.nombre)

          this.$router.push({name: modulo.nombreRutaInicio})
        } else {
          iu.msg.info(`No cuentas con el módulo \"${modulo.nombre.toUpperCase()}\" activado`)
          this.$router.push({name: 'sistemaCuenta'})
          return
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar cuenta'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
};
</script>

<style scoped>
</style>